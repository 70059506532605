<template>
<div>
  <item-list-view
      :api="api"
      :headers="headers"
      id-key="uuid"
      title="Статьи"
      search-placeholder="Поиск по заголовку"
      :allowed-search="true"
      :allowed-create="true"
      :allowed-remove="true"
      :create-route="{ name: 'BlogPost', params: { id: 'new' }}"
      update-route="BlogPost"
      remove-title="Удаление статьи"
      remove-message="Вы действительно хотите удалить статью '<%= item.title %>'?"
  >
    <template v-slot:item.title="{ item }">
      <router-link
        class="text-decoration-none"
        style="padding: 8px; display: block"
        :to="{ name: 'BlogPost', params: { id: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
    <template v-slot:item.publishedAt="{ item }">
      {{ item.publishedAt | dateTime }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip small label dark :color="item.status === statuses.PUBLISHED ? 'green' : 'red'">
        {{ item.status === statuses.PUBLISHED ? 'Да' : 'Нет' }}
      </v-chip>
    </template>
  </item-list-view>
</div>
</template>

<script>
import api from '../../services/posts'
import ItemListView from '../../components/ItemListView'

export default {
  components: {
    ItemListView,
  },
  data: () => ({
    booking: null,
    api: api,
    headers: [
      { text: 'Заголовок', value: 'title' },
      { text: 'Создан', value: 'createdAt' },
      { text: 'Опубликован', value: 'status' },
      { text: 'Опубликован в', value: 'publishedAt' },
      { text: '', value: 'action' }
    ],
    statuses: api.statuses
  })
}
</script>
