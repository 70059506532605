var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('item-list-view',{attrs:{"api":_vm.api,"headers":_vm.headers,"id-key":"uuid","title":"Статьи","search-placeholder":"Поиск по заголовку","allowed-search":true,"allowed-create":true,"allowed-remove":true,"create-route":{ name: 'BlogPost', params: { id: 'new' }},"update-route":"BlogPost","remove-title":"Удаление статьи","remove-message":"Вы действительно хотите удалить статью '<%= item.title %>'?"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"padding":"8px","display":"block"},attrs:{"to":{ name: 'BlogPost', params: { id: item.uuid } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt))+" ")]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.publishedAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":item.status === _vm.statuses.PUBLISHED ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status === _vm.statuses.PUBLISHED ? 'Да' : 'Нет')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }